<template>
    <div class="mt-2">
        <p class="text-[14px] text-gray-900 font-thin">
            Следуя инструкции, загрузите в профиль до {{LIMIT}} своих знаковых работ. С помощью которых можно составить ясное
            представление о стиле, концепции, технике в вашем творчестве.
        </p>
    </div>
    <form id="formUploadPicture" enctype="multipart/form-data" @submit.prevent @dragenter.prevent @dragover.prevent>
        <BaseLoader v-if="loading" class="left-40 inset-y-2/4" />
        <div class="space-y-7">
            <div>
                <div class="mt-8 flex justify-center rounded-lg border border-dashed border-primary px-6 py-10"
                    :class="{ 'border-red-300': message.file || collection >= LIMIT, 'drag-over': isDragOver }" @dragover.prevent="onDragOver"
                    @drop.prevent="onDrop" @dragleave="onDragLeave">
                    <div class="text-center">
                        <p v-if="!file" class="mb-6 text-sm">Выберите изображения из файлов или перетащите их в эту область
                        </p>
                        <div v-if="collection < LIMIT">
                            <label v-if="!file" @click="selectImage" class="modal-submit-button cursor-pointer"
                                title="Загрузить работу">
                                Загрузить работу
                                <IconsPlus />
                            </label>
                            <input type="file" class="file-input" accept="image/jpeg,image/png,image/mpeg" ref="fileInput"
                                @input="pickFile" @change="pickFile" name="file" :disabled="loading" />
                        </div>
                        <div v-if="collection >= LIMIT">
                            <label class="modal-submit-button-gray cursor-pointer"
                                title="Загрузить работу">
                                Загрузить работу
                                <IconsPlus />
                            </label>
                        </div>

                        <div v-if="file" class="image-preview-wrapper">
                            <div class="image-preview bg-cover bg-center" :style="{ 'background-image': `url(${file})` }"
                                @click="selectImage"></div>
                            <div class="image-preview_btn-container">
                                <button class="img-btn" title="Заменить" @click="selectImage"></button>
                            </div>
                            <p class="m-auto px-12 mt-6 text-xs font-thin text-gray-600">
                                <span>{{ fileName }}</span> <span>({{ fileSize }})</span>, загрузка завершена
                            </p>
                        </div>
                        <p v-if="!file" class="m-auto px-12 mt-6 text-xs font-thin text-gray-600">MPEG, JPEG или PNG, не
                            менее 2 МB, строго анфас, без рамы, фонов и тд. Все изображения проходят модерацию
                        </p>
                    </div>
                </div>
            </div>
            <p v-if="message.file" class="mt-2 text-xs text-red-600 border-red-400">
                {{ message.file }}
            </p>
            <p v-if="collection >= LIMIT" class="mt-2 text-xs text-red-600 border-red-400">
                Достигнуто ограничение в {{LIMIT}} загруженных работ
            </p>

            <div>
                <label for="name" class="modal-input-label">Название работы</label>
                <div class="mt-1">
                    <input v-model="name" id="name" name="name" type="text" placeholder="Васильки в небе" required
                        class="block w-full appearance-none rounded-full border border-gray-300 px-3 py-2 placeholder-gray-300 font-thin shadow-sm text-xs sm:text-sm"
                        :class="{ 'border-red-300': message.name }" :disabled="loading">
                </div>
                <p v-if="message.name" class="mt-2 text-xs text-red-600 border-red-400">
                    {{ message.name }}
                </p>
            </div>

            <div>
                <label for="period" class="modal-input-label">Год или период создания</label>
                <div class="mt-1">
                    <input v-model="period" id="period" name="period" type="text" placeholder="Год или период" required
                        class="block w-full appearance-none rounded-full border border-gray-300 px-3 py-2 placeholder-gray-300 font-thin shadow-sm text-xs sm:text-sm"
                        :class="{ 'border-red-300': message.period }" :disabled="loading">
                </div>
                <p v-if="message.period" class="mt-2 text-xs text-red-600 border-red-400">
                    {{ message.period }}
                </p>
                <p v-else class="modal-input-notice">В свободной форме</p>
            </div>

            <div>
                <label for="desc" class="modal-input-label">Описание</label>
                <UiTextarea v-model="desc" name="desc" id="desc" rows="4" :placeholder="'Введите текст описания'"
                    :disabled="loading" :validation="!!message.desc" />
            </div>
            <p v-if="message.desc" class="mt-2 text-xs text-red-600 border-red-400">
                {{ message.desc }}
            </p>


            <UiMessageBlue v-if="hideShow" v-model:hideShow="hideShow"
                description="Текстовое сопровождение позволяет зрителю лучше понять, чем вы руководствовались и как рассуждали, создавая ту или иную работу. Продуманное и детальное портфолио дает возможность лучше понять ваш индивидуальный подход и образ мыслей." />

            <div>
                <label for="category" class="modal-input-label">Выбрать категорию</label>
                <UiSelect class="modal-input" v-model:modelValue="category" :options="sortOptions" :disabled="loading"
                    :name="'category'" />
            </div>

            <div>
                <label for="materials" class="modal-input-label">Материалы, техника</label>
                <div class="mt-1">
                    <input v-model="materials" id="materials" name="materials" type="text" placeholder="Масло, холст"
                        required
                        class="block w-full appearance-none rounded-full border border-gray-300 px-3 py-2 placeholder-gray-300 font-thin shadow-sm text-xs sm:text-sm"
                        :disabled="loading">
                    <p v-if="message.materials" class="my-2 text-xs text-red-600 border-red-400">
                        {{ message.materials }}
                    </p>
                    <p v-else class="modal-input-notice mb-3">В свободной форме, через запятую</p>
                </div>

                <label class="text-base font-medium text-gray02-100 dark:text-gray02-20">Укажите размер</label>

                <div class="grid grid-flow-col justify-stretch gap-5">

                    <div class="pt-4">
                        <label for="width" class="modal-input-label">Ширина</label>
                        <div class="mt-1">
                            <input :disabled="loading" v-model="width" name="width" id="width" type="number"
                                placeholder="см" required
                                class="block w-full appearance-none rounded-full border border-gray-300 px-3 py-2 placeholder-gray-300 font-thin shadow-sm text-xs sm:text-sm"
                                :class="{ 'border-red-300': message.width }">
                            <p v-if="message.width" class="mt-2 text-xs text-red-600 border-red-400">
                                {{ message.width }}
                            </p>
                        </div>
                    </div>

                    <div class="pt-4">
                        <label for="height" class="modal-input-label">Высота</label>
                        <div class="mt-1">
                            <input :disabled="loading" v-model="height" name="height" id="height" type="number"
                                placeholder="см" required
                                class="block w-full appearance-none rounded-full border border-gray-300 px-3 py-2 placeholder-gray-300 font-thin shadow-sm text-xs sm:text-sm"
                                :class="{ 'border-red-300': message.height }">
                        </div>
                    </div>
                </div>

            </div>

            <UiValidationMessageError v-if="errorValidationMessageToggle == true && errorShow == true"
                v-model:show="errorShow" />

            <div class="mt-5 sm:mt-6">
                <UiPrimaryButton :disabled="loading" :text="'Сохранить'" @click="saveData" />
            </div>

        </div>
    </form>
</template>

<script>
import { usePicturesStore } from '~~/stores/pictures'
import { useUserData } from '~~/stores/userData';
import { storeToRefs } from 'pinia'
import { useCheckPicture } from '~~/hooks/useCheckValidation'

export default defineComponent({
    setup() {
        const config = useRuntimeConfig();
        const storePictures = usePicturesStore();
        const storeUserData = useUserData();
        const { errorValidationMessage } = storeToRefs(storePictures);
        const { message, pictures } = storeToRefs(storePictures);
        const { userData } = storeToRefs(storeUserData);
        const loading = storePictures.isPostsLoading;
        const errorValidationMessageToggle = errorValidationMessage;
        const LIMIT = ref(config.public.UPLOAD_SLOT_LIMIT);
        const hideShow = ref(true);
        const userId = ref(null);
        const collection = ref(0);

        function getAuthorsCollection(id) {
            if (pictures.value.length) {
                const result = pictures.value.filter(el => el.author.id === id).length;
                return result;
            }
            return 0;
        }; 

        if (userData.value) {
            userId.value = userData.value.id;
            if (userData.value.id) { 
                collection.value = getAuthorsCollection(userId.value);
            }
        };

        return { LIMIT, collection, storePictures, loading, errorValidationMessageToggle, message, hideShow }
    },
    props: {
        show: {
            type: Boolean
        },
        message: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            checkDefault: false,
            fileName: "",
            fileSize: "",
            file: null,
            name: "",
            period: "",
            desc: "",
            format: "circle",
            width: "",
            height: "",
            materials: "",
            category: "",      //выбранная категория
            sortOptions: [
                { value: 'Живопись', name: 'painting', option: 'Живопись' },
                { value: 'Графика', name: 'graphic-arts', option: 'Графика' },
                { value: 'Объект', name: 'object', option: 'Объект' },
                { value: 'Тиражные работы', name: 'circulation-works', option: 'Тиражные работы' },
                { value: 'Другое', name: 'other', option: 'Другое' },
            ],
            isDragOver: false,
            errorShow: false
        }
    },
    methods: {
        pickFile() {
            let input = this.$refs.fileInput
            let fileInput = input.files

            this.fileName = fileInput[0].name
            let size = fileInput[0].size
            this.fileSize = this.formatBytes(size)

            if (fileInput && fileInput[0]) {
                let reader = new FileReader()

                reader.onload = e => {
                    this.file = e.target.result
                }
                reader.readAsDataURL(fileInput[0])
                this.$emit('input', fileInput[0])
            }
        },
        formatBytes(bytes, decimals = 2) {
            if (bytes === 0) {
                return '0';
            } else {
                var k = 1024;
                var dm = decimals < 0 ? 0 : decimals;
                var sizes = ['байт', 'КБ', 'МБ', 'ГБ', 'ТБ'];
                var i = Math.floor(Math.log(bytes) / Math.log(k));
                return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
            }
        },
        selectImage() {
            this.$refs.fileInput.click()
        },
        onDragOver(event) {
            event.preventDefault();
            event.dataTransfer.dropEffect = "copy";
            this.isDragOver = true;
        },
        onDrop(event) {
            event.preventDefault();
            this.isDragOver = false;
            const file = event.dataTransfer.files[0];
            this.handleDroppedFile(file);
        },
        onDragLeave(event) {
            event.preventDefault();
            this.isDragOver = false;
        },
        handleDroppedFile(file) {
            if (file && file.type.includes("image")) {
                let reader = new FileReader();
                reader.onload = (e) => {
                    this.file = e.target.result;
                };
                reader.readAsDataURL(file);

                this.$emit("input", file);

                this.fileName = file.name;
                this.fileSize = this.formatBytes(file.size);
            }
        },
        checkPost() {
            if (!this.errorValidationMessageToggle) {
                this.$emit("update:show", false);
                this.$emit("update:message", true);
                setTimeout(() => window.location.reload(), 1000);
            }
            return;
        },
        async saveData() {
            const { check } = useCheckPicture({
                file: this.file,
                name: this.name,
                period: this.period,
                desc: this.desc,
                width: this.width,
                height: this.height,
                materials: this.materials,
            }, this.checkDefault)
            if (check && this.errorValidationMessageToggle) {
                this.errorShow = true;
                return
            }
            if (check && !this.errorValidationMessageToggle) {
                this.storePictures.changeIsPostsLoading(true)
                await this.storePictures.createPicture()
                if (!this.errorValidationMessageToggle) this.checkPost()
            }
        },
    },
})
</script>

<style>
.drag-over {
    border-color: #5a5a6b;
}
.left-40{
    left: 40%;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
    .imagePreviewWrapper{
        width: 100px;
        height: 100px;
        border: 2px solid black;
    }
    .file-input{
        opacity: 0;
        position: absolute;
        z-index: -1;
    }
    .image-preview-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .image-preview{
        width: 100px;
        height: 100px;
        border-radius: 10px;
    }
    .image-preview_btn-container{
        margin-top: 10px;
        width: 150px;
        display: flex;
        justify-content: center;
        position: relative;
    }
    .image-preview_btn-container button::before{
        content: "";
        position: absolute;
        top: 13px;
        left: 12px;
        width: 15px;
        height: 15px;
    }
    .image-preview_btn-container button:nth-child(1)::before{
        background: url('/images/icons/edit-3.svg') 100%/100% no-repeat;
    }

    .img-btn{
        width: 40px;
        height: 40px;
        background-color: #DFDFFF;
        border-radius: 50%;
        position: relative;
    }
    .img-btn:hover{
        background-color: #6366F1;
        opacity: 0.8;
    }

    .modal-submit-button-gray {
        @apply
        rounded-full
        bg-slate-400
        px-4
        py-2.5
        text-sm
        font-thin
        text-white
        shadow-sm
    }    
</style>