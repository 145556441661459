<template>
    <select :name="name" :disabled="disabled" @change="changeOption" class="ui-select">
    <option disabled value="">Выберите из списка</option>
    <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
    >
        {{ option.option }}
    </option>
    </select>
</template>

<script>
export default {
    name: 'my-select',
    props: {
        modelValue: {
            type: String
        },
        options: {
            type: Array,
            default: () => []
        },
        disabled: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ""
        },
        // selected: {
        //     type: Boolean,
        //     default: false
        // }        
    },
    methods: {
        changeOption(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    }
}
</script>

<style scoped>
 .ui-select {
   @apply
   mt-1
   block
   w-full
   rounded-md
   border-gray-300
   py-2
   pl-3
   pr-10
   text-xs
   cursor-pointer
 }
</style>